<template>
  <div class="d-flex align-center">
    <v-avatar
      :color="item.avatar ? '' : 'primary'"
      :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
      :size="item.size || 30"
      rounded
    >
      <v-img
        v-if="item.avatar"
        @click.stop="$emit('clicked')"
        class="  my-auto"
        :lazy-src="require(`@/assets/images/avatars/1.png`)"
        :src="item.avatar || require(`@/assets/images/avatars/1.png`)"
      ></v-img>
      <span
        v-else
        class="font-weight-semibold text-body-2"
      >{{ avatarText(item.title || item.label) }}</span>
    </v-avatar>
    <div
      v-if="item.label"
      class="d-flex flex-column ms-3"
    >
      <router-link
        :to="item.to"
        class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
      >
        {{ item.label }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'

export default {
  props: {
    options: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const item = computed({
      get: () => props.options,
      set: value => emit('update:object', value),
    })

    return {
      item,
      avatarText,
    }
  },
}
</script>
