<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="onSubmit"
  >
    <v-card
      height="100%"
      flat
    >
      <v-card-title>
        <slot name="form-title">
          <span class="font-weight-semibold text-base text--primary">{{ $t("Modification") }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="$emit('update:is-form-active',false)"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </slot>
      </v-card-title>
      <v-divider />
      <v-card-text
        class="overflow-y-auto"
        style="max-height: 500px;"
      >
        <v-row
          v-if="isFormActive"
          class="pt-5"
        >
          <v-col cols="12">
            {{ options.info }}
          </v-col>
          <v-col
            v-for="field in options.fields || []"
            :key="field.value"
            cols="12"
          >
            <!--            <v-datetime-picker v-if="field.time" :label="field.label"  v-model="item[field.value]" :text-field-props="{dense: true, 'hide-details': true }" />-->
            <template v-if="field.type === 'text'">
              <translatable-text-field
                v-if="field.translatable"
                v-model="item[field.value]"
                :object="item[field.value]"
                :label="field.label"
                outlined
                :readonly="field.readonly"
                dense
                clearable
              />
              <v-text-field
                v-else
                v-model="item[field.value]"
                :label="field.label"
                outlined
                :readonly="field.readonly"
                dense
                clearable
              >
              </v-text-field>
            </template>
            <v-text-field
              v-else-if="field.type === 'number'"
              v-model="item[field.value]"
              :label="field.label"
              type="number"
              outlined
              dense
              :readonly="field.readonly"
              clearable
            />
            <!--            ></v-text-field>
            <v-select
              v-else-if="field.type === 'select'"
              v-model="item[field.value]"
              :label="field.label"
              :items="field.items || []"
              :item-text="field.item_text"
              :item-value="field.item_value"
              clearable
              outlined
              dense
            ></v-select>-->
            <v-autocomplete
              v-else-if="field.type === 'select' && !field.source"
              v-model="item[field.value]"
              :label="field.label"
              :items="field.items || []"
              :item-text="field.item_text"
              :item-value="field.item_value"
              :readonly="field.readonly"
              :multiple="field.multiple"
              clearable
              outlined
              dense
            ></v-autocomplete>
            <i-autocomplete
              v-else-if="field.type === 'select' && field.source"
              v-model="item[field.value]"
              :label="field.text"
              :item-text="field.itemLabel || field.item_text || 'label'"
              :item-value="field.itemValue || field.item_value || 'id'"
              :multiple="field.multiple"
              :source="field.source"
              :object="item[field.object]"
              :readonly="field.readonly"
              clearable
              outlined
              dense
            ></i-autocomplete>
            <template v-else-if="field.type === 'date'">
              <!--              <v-datetime-picker v-if="field.time" :label="field.label"  v-model="item[field.value]" :text-field-props="{dense: true, 'hide-details': true }" />-->
              <date-picker
                v-if="!field.range"
                v-model="item[field.value]"
                :max-date="field.maxDate || null"
                :min-date="field.minDate || null"
                :readonly="field.readonly"
                :label="field.label"
              />
              <date-range-picker
                v-else
                v-model="item[field.value]"
                :max-date="field.maxDate || null"
                :min-date="field.minDate || null"
                :readonly="field.readonly"
                :range="field.range"
                :label="field.label"
              />
            </template>
            <v-color-picker
              v-else-if="field.type === 'color'"
              v-model="item[field.value]"
              :label="field.label"
              mode="hex"
              :readonly="field.readonly"
              outlined
              dense
              hide-details="auto"
            ></v-color-picker>
            <template v-else-if="field.type === 'textarea'">
              <translatable-textarea
                v-if="field.translatable"
                v-model="item[field.value]"
                outlined
                rows="2"
                auto-grow
                dense
                :label="field.label"
                :placeholder="field.label"
                :readonly="field.readonly"
                hide-details="auto"
              ></translatable-textarea>
              <v-textarea
                v-else
                v-model="item[field.value]"
                outlined
                rows="2"
                auto-grow
                dense
                :label="field.label"
                :placeholder="field.label"
                :readonly="field.readonly"
                hide-details="auto"
              ></v-textarea>
            </template>

            <template v-else-if="field.type === 'checkbox'">
              <v-switch
                v-model="item[field.value]"
                :readonly="field.readonly"
                :label="field.label"
                :true-value="options.trueValue"
                :false-value="options.falseValue"
              ></v-switch>
            </template>
            <template v-else-if="field.type === 'image'">
              <div
                class="me-3 "
                :style="{'width': field.width || '100px'}"
              >
                <span>{{ field.text || $t('Photo') }}</span>
                <file-upload
                  v-model="item[field.value]"
                  add-class="mx-auto justify-center"
                  :label="$t('Upload new photo')"
                  folder="images/packages/avatars/"
                  :btn="true"
                  :extra="false"
                  :hide-label="false"
                  icon-name="mdi-pencil"
                  :icon="false"
                  :show-download="false"
                  inputname="file"
                  :crop="true"
                  :readonly="field.readonly"
                  :aspect-ratio="field.ratio || 16/9"
                  :max-crop-height="field.cropHeight || 700"
                  :max-crop-width="field.cropWidth || 1400"
                  accept="image/*"
                >
                  <template #input>
                    <v-avatar
                      :color="item[field.value] ? '' : 'primary'"
                      :class="item[field.value] ? '' : 'v-avatar-light-bg primary--text'"
                      :width="field.width || '100px'"
                      :height="field.height || '100px'"
                      rounded
                      class="mb-4"
                    >
                      <v-img
                        v-if="item[field.value]"
                        :lazy-src="require(`@/assets/images/avatars/1.png`)"
                        :src="item[field.value] || require(`@/assets/images/avatars/1.png`)"
                      ></v-img>
                      <span
                        v-else
                        class="font-weight-semibold text-5xl"
                      >{{ avatarText(field.text) }}</span>
                      <v-icon
                        size="20"
                        color="primary"
                        style="position: absolute;bottom: 10px; left: 10px"
                      >
                        {{ icons.mdiCloudUploadOutline }}
                      </v-icon>
                    </v-avatar>
                  </template>
                </file-upload>
              </div>
            </template>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <slot name="form-actions">
          <slot name="form-cancel">
            <v-btn
              :loading="loading"
              color="secondary"
              outlined
              type="reset"
              @click="dialog = false"
            >
              {{ $t('Cancel') }}
            </v-btn>
          </slot>
          <v-spacer />
          <v-btn
            :loading="loading"
            color="primary"
            class="me-3"
            type="submit"
          >
            {{ $t("Confirm") }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="successful?$vuetify.theme.currentTheme.success:$vuetify.theme.currentTheme.error"

      dark
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </v-form>
</template>

<script>

import {
  mdiClose, mdiPencilOutline, mdiPencilOffOutline, mdiCalendar, mdiCloudUploadOutline,
} from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import axios from '@axios'
import { format, parse } from 'date-fns'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import AvatarName from '@/components/AvatarName'

import DatePicker from '@/components/DatePicker'
import FileUpload from '@/components/file_upload'
import IAutocomplete from '@/components/IAutocomplete'
import TranslatableTextarea from '@/components/TranslatableTextarea'
import TranslatableTextField from '@/components/TranslatableTextField'
import DateRangePicker from '@/components/DateRangePicker'

export default {
  components: {
    DateRangePicker,
    TranslatableTextField,
    TranslatableTextarea,
    IAutocomplete,
    FileUpload,
    DatePicker,
    AvatarName,
  },
  model: {
    prop: 'object',
    event: 'update:is-form-active',
  },
  props: {
    object: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Object,
      default: () => {},
    },
    selected: {
      type: Array,
      default: () => [],
    },
    config: {
      type: Object,
      required: true,
    },
    isFormActive: {
      type: Boolean,
    },
    group: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const form = ref()
    const errorMessages = ref({ })
    const isSnackbarBottomVisible = ref(false)
    const snackBarMessage = ref('')
    const successful = ref(false)
    const valid = ref(false)
    const modalDate = ref(false)
    const dialogDate = ref(false)
    const isFormAuthorActive = ref(false)
    const loading = ref(false)
    const isLoadingPublications = ref(false)
    const authors = ref([])
    const isLoadingAuthors = ref(false)
    const search = ref(null)
    const blocked = ref([
      { id: 'DD', label: 'Dossier décalé' },
      { id: 'Pmt', label: 'Paiement' },
      { id: 'NS', label: 'No signé' },
      { id: 'FW', label: 'Fichier Word' },

      /* { id: "vide", label: 'Ouvert' }, */
    ])
    const maxDate = ref((new Date(Date.now())).toISOString().substr(0, 10))

    const getItemValue = (model, str, d = null) => {
      if (model == null || !str) {
        return d
      }
      try {
        if (str.indexOf('.') < 0) {
          return model[str] || d
        }
        const current = str.slice(0, (str.indexOf('.')))
        const next = str.slice((str.indexOf('.')) + 1)
        model = model[current]

        return (model) ? this.getItemValue(model, next, d) : ''
      } catch (e) {
        return d
      }
    }
    const getItem = (items, field, value) => {
      for (let i = 0; i < items.length; i++) {
        if (items[i][field] == value) {
          return items[i]
        }
      }

      return false
    }

    const validate = () => {
      form.value.validate()
    }
    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })
    const dialog = computed({
      get: () => props.isFormActive,
      set: value => emit('is-form-active', value),
    })

    const author = computed({
      get: () => getItemValue(item.value, props.config.author),
    })
    const manuscript = computed({
      get: () => getItemValue(item.value, props.config.manuscript, item.value),
    })

    if (props.group) {
      props.options.fields.map(ele =>

      // ele.edit = false

        ele)
      console.log(props.options.fields)
    }

    const onSubmit = () => {
      if (valid.value) {
        const payload = {
          id: item.value.id,
          column: props.options.column_name || props.options.value,
          label: props.options.text,
          label: props.options.text,
          group: props.group,
        }
        if (props.selected && props.selected.length > 0) {
          payload.ids = props.selected.map(ele => ele.id)
        } else {
          payload.ids = [item.value.id]
        }
        if (props.options.fields && props.options.fields.length > 0) {
          for (let i = 0; i < props.options.fields.length; i++) {
            if (props.options.fields[i].required && !item.value[props.options.fields[i].value]) {
              isSnackbarBottomVisible.value = true
              successful.value = false
              snackBarMessage.value = props.options.fields[i].requiredMessage || `The field "${props.options.fields[i].label}" is required`

              return false
            }
            if (props.options.fields[i].value === props.options.value) {
              payload.with_value = true
            }
            payload[`${props.options.fields[i].value}_type`] = props.options.fields[i].type
            if (props.options.fields[i].associated) {
              payload.associated = props.options.fields[i].associated
            }
            if (props.options.fields[i].multiple) {
              payload.multiple = props.options.fields[i].multiple
            }
            payload[props.options.fields[i].value] = item.value[props.options.fields[i].value]
          }
        }

        loading.value = true
        axios.post(`${props.config.url}${props.options.url || props.options.value}/${payload.ids[0]}`,
          payload)
          .then(response => {
            loading.value = false
            if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
              if (response.data.errors) {
                // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
                errorMessages.value = { ...response.data.errors }
              } else {
                successful.value = false
                isSnackbarBottomVisible.value = true
                snackBarMessage.value = response.data.message

                // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
              }

              return true
            }

            item.value = response.data
            snackBarMessage.value = props.options.success_message || `${props.options.text} successfully saved`
            isSnackbarBottomVisible.value = true
            successful.value = true

            // emit('update:is-form-active', false)
            emit('saved', response.data)

            // item.value.comment = null
          }).catch(error => {
            isSnackbarBottomVisible.value = true
            successful.value = false
            snackBarMessage.value = 'Error saving'
            loading.value = false
            console.log('error', error)
            if (error.data && error.data.errors) {
            // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              errorMessages.value = { ...error.data.errors }
            }
          })
      } else {
        validate()
      }
    }

    const setColor = () => {
      if (item.value[props.options.colors] && item.value[props.options.colors].length > 0) {
        const records = item.value[props.options.colors].filter(ele => ele[props.options.color_reference] === (props.options.color_column || props.options.value))

        if (records && records.length) {
          item.value.color_id = records[0].color_id || null
          item.value.fore_color_id = records[0].fore_color_id || null
        }
      } else {
        item.value.color_id = null
      }
    }
    setColor()
    watch(item, () => {
      // setColor()

    }, {
      deep: true,
    })

    return {
      form,
      maxDate,
      blocked,
      isFormAuthorActive,
      isLoadingPublications,
      isSnackbarBottomVisible,
      snackBarMessage,
      errorMessages,
      successful,
      search,
      authors,
      author,
      manuscript,
      isLoadingAuthors,
      onSubmit,
      modalDate,
      dialogDate,
      item,
      valid,
      dialog,
      loading,
      validate,
      avatarText,

      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
        mdiPencilOffOutline,
        mdiPencilOutline,
        mdiCalendar,
        mdiCloudUploadOutline,
      },
    }
  },
}
</script>
